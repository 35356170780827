import React, { useState, useContext, useEffect } from 'react'
import SiteContext from '../SiteContext';
import axios from 'axios';
import { Link } from 'gatsby';
import calculateVoters from '../../utils/calculateVoters';

const StepForm = ({ display, handleSubmit, formData, voters, handleChange, setNextStep}) => (
  <>
    <div className={`${display ? '' : 'd-none'}`}>
      <p><span className="input-warning">You should register {voters} voters.</span> Please use your full voter allocation - to ensure as many colleagues as possible are able to help shape the City's future.</p>

      <p>Send this information to City of London elections team:</p>
        <form
          onSubmit={ (e) => handleSubmit(e) } 
          className="form form--business"
          name="Business form" 
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
          id="business-form"
        >
       
        <div className="field-group  mb-3">
          <div className="input-wrapper">
            <input 
              onChange={(e) => handleChange(e)} 
              type="text" 
              value={formData.name} 
              name="name" 
              id="name" 
              placeholder="Name*"
              required
            />
            <span className={`input__custom-label ${ formData.name ? 'show' : '' }`}>Name</span>
          </div>

          <div className="input-wrapper">
            <input 
              onChange={(e) => handleChange(e)} 
              type="text" 
              value={formData.organization} 
              name="organization" 
              id="organization" 
              placeholder="Name of Organisation*"
              required
            />
            <span className={`input__custom-label ${ formData.organization ? 'show' : '' }`}>Organisation</span>
          </div>
        </div>

        <div className="field-group">
          <div className="input-wrapper">
            <input 
              onChange={(e) => handleChange(e)} 
              type="string" 
              value={formData.postcode} 
              name="postcode" 
              className="required postcode" 
              id="postcode" 
              placeholder="Workplace Postcode*"
              required
            />
            <span className={`input__custom-label ${ formData.postcode ? 'show' : '' }`}>Postcode</span>
          </div>

          <div className="input-wrapper">
            <input 
              onChange={(e) => handleChange(e)} 
              type="email" 
              value={formData.email} 
              name="email" 
              className="required email" 
              id="email" 
              placeholder="Email*"
              required
            />
            <span className={`input__custom-label ${ formData.email ? 'show' : '' }`}>Email</span>
          </div>
        </div>

        <input name="employees" className="d-none" type="text" value={formData.employees}  />

        <div className="button-group">
          <button className="button--submit" type="submit">Send and continue <span className="button-arrow">›</span></button>
          <span className="button-group__text">or</span>
          <Link to="/" onClick={ (e) => setNextStep(e) }>Continue without sending <span className="button-arrow">›</span></Link>
        </div>
      </form>
    </div>
  </>  
)

export default function BusinessForm() {
  const [currentStep, setCurrentStep] = useContext(SiteContext);
  const [formData, setFormData] = useState({
    organization: "",
    employees: "",
    name: "",
    email: "",
    postcode: ""
  });

  const mailSubject = "Speak for the City: New Business Submition";


  // = = = = = = = = = = = = =
  // Events Listeners
  
  // 
  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

    // Add consent if user didn't interact with it's radio buttons
    if(formData.consent === undefined) {
      setFormData({
        ...formData,
        consent: 'yes'
      });
    }
  }

  //
  const handleSubmit = (e) => {
    e.preventDefault();
    let myForm = e.target;
    
    fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        "form-name": myForm.getAttribute('name'),
        ...formData
      }).toString()
    })
    .then(async (response) => {
      if (!response.ok) throw Error(response.statusText);
      // Send to Serverless Function
      axios.post('/.netlify/functions/mailGunFinal', {
        notificationMailgun: 'calculatevoterentitlement.notification',
        notificationSubject: 'Notification of Calculate Voter Entitlement Submition',
        confirmationMailgun: 'calculatevoterentitlement.confirmation',
        confirmationSubject: 'Thank you for getting in touch',
        userName: formData.name,
        userEmail: formData.email,
        organization: formData.organization,
        postcode: formData.postcode,
        employees: formData.employees
      })
    })
    .then(response => {
      console.log("Thank you!");
      // Empty Form
      setFormData({});
      setNextStep(e);
    })
    .catch((error) => {
      alert(error);
    })
  }

  //
  const setNextStep = (e) => {
    e.preventDefault();
    const nextStep =  currentStep + 1;
    setCurrentStep(nextStep);
  }

  return (
    <div className="form form--business">
      <input type="hidden" name="form-name" value="Business form" />

      <div className="field-group">
        <label>Number of workers</label>
        <div className="input-wrapper">
          <input 
            className="workers-input"
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.employees} 
            name="employees" 
            id="employees" 
            placeholder="eg: 40"
          />
        </div>
      </div>

      {
        formData.employees 
        ?
          <StepForm 
            display={formData.employees}
            handleSubmit={handleSubmit}
            voters ={ calculateVoters(formData.employees) }
            formData={formData}
            handleChange={(e) => handleChange(e) } 
            setNextStep={(e) => setNextStep(e)}
          />
        : ''
      }
    </div>
  )
}