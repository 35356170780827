import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export default function Step4({ handleClick }) {

  return(
    <section id="step-5" className="step step--1-col step--5">
      <div className="container">

        <div className="row">

          <div className="col-md-12">

            <div className="step__headline">
                <h1>Step 5: Register Your Voters Online</h1>
            </div>

          </div>

        </div>

        <div className="background-wrapper">
          <StaticImage 
            src="../../../static/media/step-4.png"
            alt=""
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="row">

          <div className="col-md-10 offset-md-1">

            <div className="content-wrapper">

              <div className="step__content">
                <p>The voter registration deadline is 30th November.</p>
                <p>You can easily register your voters online with a code you that was sent to your organisation through the post.</p>
                <p>If you have not received a registration code, you can request one now.</p>
              </div>

              <div className="step__ctas">
                <Link className="button" to="/request-online-registration-code">Send me my registration code ›</Link>
              </div>

            </div>

            <div className="step__nav">
              <Link class="back-link" to="/" id="back" onClick={ (e) => handleClick(e) }>‹ Go back</Link>
              <Link class="back-link" to="/" id="back">Finish ›</Link>
            </div>

          </div>
         

        </div>

      </div>
    </section>
  )
}