import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export default function TwoColStep ({ step, handleClick }) {

  return(
    <section id="step-2" className="step step--2">
        <div className="container">

          <div className="row">

            <div className="col-md-12">

              <div className="step__headline">
                  <h1>Step 2: Who is eligible?</h1>
              </div>

            </div>

          </div>

          <div className="background-wrapper">
            <StaticImage 
              src="../../../static/media/step-3-bg.png"
              alt=""
              placeholder="blurred"
              loading="eager"
            />
          </div>

          <div className="row">

            <div className="col-md-10 offset-md-1">

              <div className="content-wrapper">

                <div className="step__content">

                  <p>The voters you appoint must meet one of the following criteria, which can include long-term sub-contractors, agency staff and part-time workers:</p>
                  <ul>
                    <li>-Have worked for your organisation in the City, as their principal place of work, since 1st September last year.</li>
                    <li>-<b>OR</b> have worked for your organisation in the City, as their principal place of work, for a combined period of five years or more - with some of this taking place in the five years leading up to 1st September this year.</li>
                    <li>-<b>OR</b> have worked in the City, as their principal place of work, for any organisation - for a combined period of ten years or more.</li>
                    <li>-<b>OR</b> be a member of your Board of Directors or Governing Body on 1st September this year, and having been so for the whole of the previous year.</li>
                  </ul>
                  <p>They must also:</p>
                  <ul>
                    <li>-Be a UK, Irish, Commonwealth or EU* citizen (*see updated guidelines)</li>
                    <li>-Be aged 17 by 15th February 2025 (they must be 18 to cast their vote)</li>
                  </ul>
                  <p>
                    <b>Voting rights have changed for EU citizens</b><br />
                    EU can only be added to the ward list if they are:
                  </p>
                  <ul>
                    <li>-A British, Irish, or Commonwealth citizen; or</li>
                    <li>-A citizen of Denmark, Luxembourg, Poland, Portugal, or Spain who has permission to enter or stay in the UK, Channel Islands or Isle of Man, or who does not need permission</li>
                    <li>-A citizen of any other EU country who on or before 31 December 2020 had permission to enter or stay in the UK, Channel Islands or Isle of Man, or who did not need permission, and this has continued without a break.</li>
                  </ul>
                  <p>More details can be found at: <a href="https://www.cityoflondon.gov.uk/workervote" target="_blank">www.cityoflondon.gov.uk/workervote</a></p>
                </div>

                <div className="step__ctas">
                  <button onClick={ (e) => handleClick(e) }>Continue ›</button>
                </div>

              </div>

              <div className="step__nav">
                <Link className="back-link" to="/" id="back" onClick={ (e) => handleClick(e) }>‹ Go back</Link>
              </div>
              
            </div>

          </div>
        </div>
    </section>
  )
}