import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useState } from "react";
import IconCloseModal from "../icons/IconCloseModal";
import downloadFile from '../../images/staff-template.pdf' 

const SocialWindow = ({ showSocialWindow }) => (
  <div className="social-modal">
    <div className="step__content">
      <h3 className="text-center">Share this page with your friends</h3>
    </div>
     
    <div className="step__ctas">
      <a href={`http://www.facebook.com/share.php?u=${'https://www.speakforthecity.com/'}`} target="_blank" className="button link--facebook">Share it on Facebook</a>
      <a href={`https://twitter.com/intent/tweet?text=${'https://www.speakforthecity.com/'}`} target="_blank" className="button link--twitter">Share it on Twitter</a>
      <a href={`https://api.whatsapp.com/send?text=${'https://www.speakforthecity.com/'}`} target="_blank" className="button link--whatsapp">Share it on Whatsapp</a>
    </div>
    <div className="close-link">
      <Link onClick={(e) => showSocialWindow(e)}  to="/">Close</Link>
    </div>
  </div>
)

export default function Step({ handleClick }) {
  const [showSocials, setShowSocials] = useState(false)

  const showSocialWindow = (e) => {
    e.preventDefault();
    setShowSocials(!showSocials);
  }

  return (
    <section id="step-4" className="step step--1-col step--4">
    <div className="container">

      <div className="row">

        <div className="col-md-12">

          <div className="step__headline">
              <h1>Step 4: Selecting your voters</h1>
          </div>

        </div>

      </div>

      <div className="background-wrapper">
          <StaticImage 
            src="../../../static/media/step-0-bg.png"
            alt=""
            placeholder="blurred"
            loading="eager"
          />
        </div>

      <div className="row">

        <div className="col-md-10 offset-md-1">

          <div className="content-wrapper">

            <div className="step__content">

              <h3 className="text-center">Voter registration is a chance to showcase your diversity.</h3>
              <p>The law requires that your voters reflect your workforce. You should, where possible, register a diverse cross-section of your organisation and should avoid only registering senior staff. </p>
              <p>The process of appointing voters should be open and clear. If your organisation has staff networks, who represent the interests of the wider workforce, these can provide a perfect source of voting representatives. Please use your full voter allocation - to ensure as many colleagues as possible are able to help shape the City's future. </p>
              <p>Here are a few of the ways some City businesses have approached appointing their voters: </p>
              <ul>
                <li>-<b>Engaging staff networks:</b> Appointing the leaders of staff networks and employee resource groups or asking those group for nominations. </li>
                <li>-<b>Rewarding leadership at all levels:</b> Selecting employees that have consistently shown internal leadership and a willingness to engage with the local community. </li>
                <li>-<b>Opening self-nominations:</b> Allowing staff to put their names forward and selecting a set of electors that best represents the diversity of the organisation.</li>
              </ul>              
            </div>

            <div className="step__ctas">
              {/* <a href={downloadFile} download className="button button--download">Download template ›</a> */}
              {/* <button onClick={ (e) => showSocialWindow(e) } className="button--share">Share with colleagues ›</button> */}
              <button onClick={ (e) => handleClick(e) }>Next ›</button>
            </div>

          </div>

          <div className="step__nav">
            <Link className="back-link" to="/" id="back" onClick={ (e) => handleClick(e) }>‹ Go back</Link>
          </div>

        </div>

      </div>

    </div>
  </section>
  )
}
