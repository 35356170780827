import React, { useContext } from 'react'
import SiteContext from './SiteContext';

import Step0 from './steps/Step-0';
import Step1 from './steps/Step-1';
import Step2 from './steps/Step-2';
import Step3 from './steps/Step-3';
import Step4 from './steps/Step-4';
import Step5 from './steps/Step-5';

export default function RegistrationSteps() {
  const [currentStep, setCurrentStep] = useContext(SiteContext);

  // = = = = = = = = = = = = =
  // Event Listeners

  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined"

  // Nav between steps
  const handleClick = (e) => {
    if (isBrowser) {
      window.scrollTo({ top: 100, behavior: 'smooth' });
    }

    e.preventDefault();
    
    let nextStep = 0;

    if(e.target.id === 'back') {
      nextStep =  currentStep - 1;
    }

    else {
      nextStep =  currentStep + 1;
    }

    if( nextStep > 0 || nextStep < 6 ) {
      setCurrentStep(nextStep);
    }
  }

  return(
    <>
      {
        currentStep === 0 ? <Step0 handleClick={(e) => handleClick(e)} /> : ''
      }
      {
        currentStep === 1 ? <Step1 handleClick={(e) => handleClick(e)} /> : ''
      }
      {
        currentStep === 2 ? <Step2 handleClick={(e) => handleClick(e)} /> : ''
      }
      {
        currentStep === 3 ? <Step3 handleClick={(e) => handleClick(e)} /> : ''
      }
      {
        currentStep === 4 ? <Step4 handleClick={(e) => handleClick(e)} /> : ''
      }
      {
        currentStep === 5 ? <Step5 handleClick={(e) => handleClick(e)} /> : ''
      }
    </>
  )
}
