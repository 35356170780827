import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import BusinessForm from "../forms/BusinessForm";

export default function Step({ handleClick }) {
  return(
    <section id="step-1" className="step step--1">
      <div className="container">

<div className="row">

  <div className="col-md-12">

    <div className="step__headline">
      <h1>Step 1: How many voters should you register?</h1>
    </div>

  </div>

</div>

<div className="row">

  <div className="col-md-5 content-1">

    <div className="content-wrapper">

      <div className="step__content">
        <h3>I already know how many voters I should register</h3>
      </div>

      <div className="step__ctas">
        <button onClick={ (e) => handleClick(e) }>Skip to next step ›</button>
      </div>

    </div>

  </div>

  <div className="col-md-7 content-2">

    <div className="content-wrapper">
      <div className="step__content">

        <h3>Calculate voter entitlement</h3>
        <p><b>How many people will have used these premises as their principal place of work on 1st September this year?</b></p>
        <p>Please note, using this does not form any part of the registration process. It is for informational purposes.</p>
        <p>Make sure to include:</p>
        <ul>
          <li>- Those who work remotely part of the time but are based at your City office. </li>
          <li>- Long-term sub-contractors, consultants and agency staff.</li>
        </ul>
        

        <BusinessForm />

      </div>

    </div>


  </div>

  <div className="step__nav">
    <Link className="back-link" to="/" id="back" onClick={ (e) => handleClick(e) }>‹ Go back</Link>
  </div>

</div>
</div>
    </section>
  )
}