import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export default function Step3 ({ handleClick }) {

 return (
    <section id="step-3" className="step step--1-col step--3">
      <div className="container">

        <div className="row">

          <div className="col-md-12">

            <div className="step__headline">
                <h1>Step 3: Inform your colleagues about their right to vote</h1>
            </div>

          </div>

        </div>

        <div className="background-wrapper">
          <StaticImage 
            src="../../../static/media/step-1-2.png"
            alt=""
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="row">

          <div className="col-md-10 offset-md-1">

            <div className="content-wrapper">

              <div className="step__content">
                <h3 className="text-center">Your colleagues may not know that they have the opportunity to vote in the City of London and rely on you to inform them of this unique right.</h3>
                <p>We have created a business toolkit to help you inform your colleagues. It includes a template messages and graphics for your internal communications. There are also printable posters.</p>
                <p>City workers cannot register individually and will need to contact you to add them to the registration form.</p>
                <p>Workplaces who make their staff aware of the opportunity to participate almost always fill their allocated voter places.</p>                
              </div>

              <div className="step__ctas">
                <a className="link--default" href="/resources" target="_blank">Open toolkit in a new window</a>
                <button onClick={ (e) => handleClick(e) }>Continue ›</button>
              </div>

            </div>

          <div className="step__nav">
            <Link className="back-link" to="/" id="back" onClick={ (e) => handleClick(e) }>‹ Go back</Link>
          </div>
          </div>


        </div>


      </div>
    </section>
  )
}