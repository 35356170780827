const calculateVoters = (workers) => {

  if(workers < 0) {
    return;
  }

  else if (workers < 6) {
    return 1;
  }

  else if(workers < 50) {
    return Math.floor(workers/5);
  }

  else if(workers >= 50) {
    return Math.floor(10 + (workers - 50) / 50);
  }
  
}

export default calculateVoters;

