import * as React from 'react'
import Seo from "../components/Seo"
import RegistrationSteps from "../components/RegistrationSteps"

export default function businessRegistration() {

  return(
    <>
      <Seo title = 'Business Registration' />

      <RegistrationSteps />
    </>
  )
}
